
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "add-user-modal",
  components: {},
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const addUserModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const formData = ref({
      name: "Sean Bean",
      email: "sean@bean.com",
      description: "TODO: Multiselect mit Projekten",
      addressLine: "Hamburger Allee 100",
      addressLine2: "",
      town: "Berlin",
      state: "Berlin",
      postCode: "12347",
      country: "DE",
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "User name is required",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "User email is required",
          trigger: "change",
        },
      ],
      addressLine: [
        {
          required: true,
          message: "Address 1 is required",
          trigger: "change",
        },
      ],
      town: [
        {
          required: true,
          message: "Town is required",
          trigger: "change",
        },
      ],
      state: [
        {
          required: true,
          message: "State is required",
          trigger: "change",
        },
      ],
      postCode: [
        {
          required: true,
          message: "Post code is required",
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          setTimeout(() => {
            loading.value = false;

            Swal.fire({
              text: "Form has been successfully submitted!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              hideModal(addUserModalRef.value);
            });
          }, 2000);
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addUserModalRef,
    };
  },
});
